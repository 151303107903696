table {
    border: 1 solid black;
    table-layout: fixed;
}

th, td {

    border: 1 solid black;
}

.toolbarContainer {

    background: lightgrey;
    font-size: 0.9em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    height: 155px;
    width: 1260px;
}

/* input.form-fixer {
    padding: 1px;
    font-size: 0.9em;
} */

/* .form-horizontal .form-group input, 
.form-horizontal .form-group select,
.form-horizontal .form-group label
 { 
    height: 19px;
    line-height: 19px;
} */