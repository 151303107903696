nav.pagerNav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333333;
}

nav.pagerNav li {
    float: left;
}

nav.pagerNav li a {
    display: block;
    color: white;
    text-align: center;
    padding: 16px;
    text-decoration: none;
}

nav.pagerNav li a:hover {
    background-color: #111111;
}