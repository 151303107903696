table {
  table-layout: fixed;
  /*border: 1px solid black;*/
}

th,
td {
  width: 20%;
  vertical-align: middle;
  /*border: 1px solid black;*/
}

td img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

div.headerContent {
  background: #54007f;
  height: 100px;
  margin-top: 5px;
  width: 1260px;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  margin-left: auto;
  margin-right: auto;
}

div.container {
  background: #54007f;
}

div.headerCell {
  background: white;
}
