.form-signin {

    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
}

.form-signin .form-signin-heading, .form-signin .checkbox {

    margin-bottom: 10px;
}

.form-signin .checkbox {

    font-weight: normal;
}

.form-signin .form-control {

    position: relative;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    font-size: 16px;
}


.form-signin .form-control:focus {

    z-index: 2;
}


.form-signin input[type="email"] {

    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}


.form-signin input[type="password"] {

    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}


.bold-text {

    font-family: inherit;
    color: yellow;
    text-align: centre;
}


.vertical-text {
    
    position:absolute;
    font-size: 12px;
    font-weight: bold;
    align-items: center;
    vertical-align: middle;
    width: 135px;
    height: 13px;
    top: -1px;
    left: 110px;
	transform: rotate(90deg);
	transform-origin: left top 0;
}


.form-actions {

    margin: 2;
    background-color: transparent;
    text-align: center;
}

.grid-header-background {

    background: #54007F;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0px;
    margin: 0px;
}


.horizontal-header-text {
    
    position: absolute;
    left: 5px;
    font-size: 15px;
    width: 100%;
    text-align: left;
    position: float;
    color: white;
}


.horizontal-tall-header-text {
    
    position:absolute;
    left: 5px;
    font-size: 15px;
    font-weight: bold;
    width: 100%;
    text-align: left;
    position: float;
    color: #FCFBFD;
    top: 115px;
}


.vertical-header-text {
    
    font-weight: bold;
    color: white;
    text-align: right; 
    vertical-align: middle;
}


/*.header-panel {

    background: #BCBCBC;
}*/


.ss-button {
    
    width: 100px;
}

.title-panel {
    
    background: #54007F;
    text-align: centre;
    border-width: 1px;
    border-color: #54007F;
    border-style: solid;
}

.horizontalDiv {
    
    float: left;
    height: 45px;
    line-height: 45px;
}

.info-header {
    font-weight: bold;
    color: #54007F;
    text-align: left; 
}

.react-grid-HeaderCell {
    color: #E5E0E0; 
    background: #E5E0E0; 
}

.react-grid-Cell .react-grid-Cell__value {
    position: absolute;
    top: 2%;
}

/* ALTERNATE ROW _ BACKGROUND-COLOR */
.react-grid-Row--odd .react-grid-Cell { 
    background-color: #E5E0E0; 
}

/* HOVER OVER ROW - BACKGROUND-COLOR */
.react-grid-Row:hover .react-grid-Cell {
    background-color: #E6B2F7; 
}

/*.nav .navbar-nav .navbar-right:hover {
    background-color: #E6B2F7; 
}
*/

.menuOption {
    color: white;    
}

.menuOption:hover {
    font-weight: bold;
    color: #54007F;    
    background: #E6B2F7; 
}

.wordWrapDiv {
    white-space: pre;
    word-break: break-all;
    word-wrap: break-word;
}
