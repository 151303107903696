.grid-div {
  width: 100%;
}

.react-grid-HeaderCell {
  color: white;
  background: #54007f !important;
}

.react-grid-Cell {
  font-size: 0.9em;
}

.react-grid-Cell__value {
  white-space: normal !important;
  text-align: top;
}
